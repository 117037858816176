<template>
  <b-card>
    <b-row id="border">
      <b-col style="margin-right: 10px">
        <h4 class="mb-3">Billing From :</h4>
        <div v-if="hasVendorData">
          <div class="mt-3 mb-3 d-flex">
            <img v-if="vendorAvatar" class="companylogo mr-3" v-bind:src="vendorAvatar" />
            <div>
              <span><i class="fa fa-building"></i>
                {{ vendorDetails.company_name }}</span>
              <br />
              <span> <i class="fa fa-user"></i> {{ usersName }} </span>
            </div>
          </div>
          <div>
            <div>
              <b>Address: </b>
              <span>{{ vendorDetails.addressline1 }},
                {{ vendorDetails.addressline2 }}</span>
            </div>
            <div>
              <b> City {{ '&' }} Province: </b>
              <span>{{ vendorDetails.city }}, {{ vendorDetails.province }}</span>
            </div>
            <div class="text-uppercase">
              <b> Postal Code: </b>{{ vendorDetails.postalcode }}
            </div>
            <div><b>Phone Num: </b>{{ vendorDetails.phone_num }}</div>
            <div><b>Fax Num: </b>{{ vendorDetails.fax_num }}</div>
            <div><b>GST: </b>{{ vendorDetails.gst }}</div>
            <div><b>RCN: </b>{{ vendorDetails.rcn }}</div>
            <div><b>Bank Account: </b>{{ vendorDetails.bankaccount }}</div>
          </div>
        </div>
        <div v-else class="mt-3 mb-3">
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
        </div>
      </b-col>
      <b-col>
        <h4 class="mb-3">Bill To :</h4>
        <div class="mt-3 mb-4" v-if="hasCompanyData">
          <img v-if="avatar" class="companylogo" v-bind:src="avatar" />
          <div class="mb-4 mt-2">
            <select style="border-radius: 5px; border-color: lightgrey; height: 38px" class="rt-select w-75"
              v-model="companyId" @change="get_company">
              <option value="0" disabled>Select Company</option>
              <option v-for="company in companyData" :value="company.companyid">
                {{ company.cn }}
              </option>
            </select>
          </div>
          <div v-if="!isNaN(Number(companyId)) && companyInfo">
            <div>
              <b>Company Address: </b>{{ companyInfo.addressline1 }},
              {{ companyInfo.addressline2 }}
            </div>
            <div>
              <b> City {{ '&' }} Province: </b> {{ companyInfo.city }},
              {{ companyInfo.province }}
            </div>
            <div><b> Postal code: </b> {{ companyInfo.postalcode }}</div>
          </div>
        </div>
        <div v-else class="mt-3 mb-3">
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4 mr-3" v-if="companyInfo">
      <div class="col-md-6">
        <h5>Attach file:</h5>
        <input onclick="this.value=null;" accept=".xlsx,.xls,.xml" class="mt-2" id="fileInput" type="file" ref="csvFile"
          @change="importInvoiceTemplate" />
      </div>
    </b-row>
    <div class="mt-4 ml-1 mb-3" v-if="errorDoc.length > 0">
      <h5>Validtion Error:</h5>
      <ul>
        <li v-for="(val, i) in errorDoc">
          {{ val.message }}
        </li>
      </ul>
    </div>
    <span v-if="isLoading" class="mb-3 ml-1 mt-4">
      Validating Invoices...
    </span>
    <b-button onclick="this.value=null;" variant="success" size="sm" class="mt-3" v-if="companyInfo"
      :disabled="!isInvoicesValidated" style="height: min-content" @click="insertImportedInvoices">Import Invoices
    </b-button>
  </b-card>
</template>

<script>
import * as jwt_decode from 'jwt-decode';
import xml from 'xml2js';
import * as XLSX from 'xlsx';
import { backend_url, xsd_version } from '../../config';


export default {
  name: 'VendorImportInvoice',
  components: {},
  data() {
    return {
      hasVendorData: false,
      hasCompanyData: false,
      vendorDetails: [],
      usersName: '',
      vendorAvatar: '',
      companyData: [],
      companyId: '',
      vendorId: '',
      companyInfo: null,
      errorDoc: [],
      doc: [],
      isInvoicesValidated: false,
      isLoading: false,
      allowedInvoiceColumns: [
        {
          xlkey: 'Cost Centre/Well (required)',
          xmlKey: 'CostCenter_well',
          value: 'vwref',
        },
        {
          xlkey: 'Vendor Invoice Number (required)',
          xmlKey: 'VendorInvoiceNumber',
          value: 'vin',
        },
        {
          xlkey: "Field Ticket Numbers (Seprated By ',')",
          xmlKey: 'FieldTicketNumbers',
          value: 'ticketnum',
        },
        {
          xlkey: 'Vendor Invoice Date (yyyy-mm-dd)',
          xmlKey: 'VendorInvoiceDate',
          value: 'invoiceDate',
        },
        {
          xlkey: 'Treatment Type',
          xmlKey: 'TreatmentType',
          value: 'treatmentType',
        },
        { xlkey: 'GST % (required)', xmlKey: 'GST', value: 'GST' },
        { xlkey: 'PST % (required)', xmlKey: 'PST', value: 'PST' },
        { xlkey: 'HST % (required)', xmlKey: 'HST', value: 'HST' },
        {
          xlkey: 'Service Order Number',
          xmlKey: 'ServiceOrderNumber',
          value: 'serviceOrderNumber',
        },
        {
          xlkey: 'Program Number',
          xmlKey: 'ProgramNumber',
          value: 'programNumber',
        },
        { xlkey: 'Comment (required)', xmlKey: 'Comment', value: 'comment' },
        { xlkey: 'Action (Draft/Submit)', xmlKey: 'Action', value: 'action' },
        {
          xlkey: 'QuickPay (yes/no)',
          xmlKey: 'QuickPay',
          value: 'quickpay',
        },
        {
          xlkey: 'QuickPay Discount %',
          xmlKey: 'QuickPay_Discount',
          value: 'discount',
        },
        {
          xlkey: 'QuickPay Net Days',
          xmlKey: 'QuickPay_Net_Days',
          value: 'days',
        },
      ],
      allowedServiceColumns: [
        { xlkey: 'Service Name', xmlKey: 'name', value: 'serviceName' },
        {
          xlkey: 'Start-End Date (yyyy/mm/dd-yyyy/mm/dd)',
          xmlKey: 'date',
          value: 'serviceDates',
        },
        { xlkey: 'UOM', xmlKey: 'UOM', value: 'uom' },
        { xlkey: 'Quantity', xmlKey: 'Quantity', value: 'quantity' },
        { xlkey: 'Discount(%)', xmlKey: 'Discount', value: 'discount' },
        { xlkey: 'GST (yes/no)', xmlKey: 'GST', value: 'GST' },
        { xlkey: 'PST (yes/no)', xmlKey: 'PST', value: 'PST' },
        { xlkey: 'HST (yes/no)', xmlKey: 'HST', value: 'HST' },
        { xlkey: 'Flexible (yes/no)', xmlKey: 'Flexible', value: 'isFlexible' },
        { xlkey: 'Price/unit (in CAD)', xmlKey: 'Service_Price', value: 'pu' },
        { xlkey: 'Comments', xmlKey: 'Comment', value: 'comment' },
      ],
      allowedCodingColumns: [
        {
          xlkey: 'AFE or Cost Center / Well',
          xmlKey: 'AFE_or_CostCenterWell',
          value: 'afenum',
        },
        {
          xlkey: 'Cost Code Chain(cost code1/cost code2/cost code3)',
          xmlKey: 'CostCodeChain',
          value: 'chain',
        },
        {
          xlkey: 'Coding Percentage %',
          xmlKey: 'CodingPercentage',
          value: 'percentage',
        },
        { xlkey: 'Service Name', xmlKey: 'ServiceName', value: 'serviceName' },
      ],
    };
  },
  mounted: async function () {
    let token = this.$session.get('jwt');
    var decoded = jwt_decode(token);
    if (!this.fake_vendor) {
      await this.$axios.get('/vendor/get/profile').then((response) => {
        this.vendorDetails = response.data;
        this.usersName = response.data.fname + ' ' + response.data.lname;
        this.hasVendorData = true;
      });
      this.vendorId = decoded.id;
      await this.$http
        .get('/get/vendor/avatar/' + decoded.id)
        .then((response) => {
          this.vendorAvatar = response.data.avatar;
        })
        .catch((error) => {
          console.log('Error getting avatar ' + error);
        });

      await this.$axios
        .get('/vendor/get/allowed_companies')
        .then((response) => {
          this.companyData = response.data.result;
          this.companyId = this.companyData[0];
          this.hasCompanyData = true;
        });
    } else {
      await this.$axios
        .get('/vendor/get/profile/' + this.fake_vendor.vid)
        .then((response) => {
          this.vendorDetails = response.data;
        });

      await this.$http
        .get('/get/vendor/avatar/' + this.fake_vendor.vid)
        .then((response) => {
          this.vendorAvatar = response.data.avatar;
        })
        .catch((error) => {
          console.log('Error getting avatar ' + error);
        });

      await this.$axios
        .get('/vendor/get/allowed_companies/' + this.fake_vendor.vid)
        .then((response) => {
          this.companyData = response.data.result;
        });
    }
  },
  methods: {
    async importInvoiceTemplate(e) {
      this.doc = [];
      this.errorDoc = [];
      this.isLoading = true;
      try {
        const file = e.target.files[0];
        if (!file) {
          this.$toasted.show('Error Reading File', {
            type: 'error',
            duration: '3000',
          });
          return;
        }

        if ('name' in file && file.name.includes('.xml')) {
          const reader = new FileReader();
          reader.addEventListener(
            'load',
            () => {
              // this will then display a text file
              xml.parseString(reader.result, async (error, result) => {
                if (error) {
                  this.$toasted.show('Error Reading XML File', {
                    type: 'error',
                    duration: '3000',
                  });
                  this.isLoading = false;
                } else {
                  if (!result) {
                    this.$toasted.show('Error Reading XML File', {
                      type: 'error',
                      duration: '3000',
                    });
                    this.isLoading = false;
                  }

                  const xsd = result["Invoices"]["$"]["xsi:noNamespaceSchemaLocation"];
                  const xsdVersionPath = `${backend_url}/vendor/get/${xsd_version}/xsd`;
                  console.log(xsdVersionPath);
                  if ((xsd === xsdVersionPath)) {
                    this.doc = this.changeColumnName(result);
                    if (this.errorDoc.length === 0 && this.doc.length > 0) {
                      await this.validateImportedInvoices();
                    } else if (this.doc.length === 0) {
                      this.$toasted.show('File is empty.', {
                        type: 'error',
                        duration: '3000',
                      });
                      this.isLoading = false;
                    }
                  } else {
                    this.$toasted.show(
                      'Incorrect XSD name or version in your XML File, please change the xsd version.',
                      {
                        type: 'error',
                        duration: '5000',
                      }
                    );
                    this.isLoading = false;
                  }
                }
              });
            },
            false
          );
          if (file) {
            reader.readAsText(file);
          }
        } else if ('name' in file && file.name.includes('.xlsx')) {
          const parsedFileData = await file.arrayBuffer();
          const workbook = XLSX.read(parsedFileData);
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const result = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: '',
            blankrows: true,
          });
          // filter out empty rows
          this.doc = this.prepareInvoiceList(result);
          if (this.errorDoc.length === 0 && this.doc.length > 0) {
            await this.validateImportedInvoices();
          } else if (this.doc.length === 0) {
            this.$toasted.show('File is empty.', {
              type: 'error',
              duration: '3000',
            });
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        } else {
          this.$toasted.show(
            'Incorrect File Format. Please upload xml or xlsx file.',
            {
              type: 'error',
              duration: '3000',
            }
          );
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    changeColumnName(obj) {
      const fileData = [];
      let counter = 1;
      try {
        for (let invoiceObj of obj.Invoices.Invoice) {
          let result = {};
          // validate invoice columns

          // cost center well
          if (
            '$' in invoiceObj &&
            this.allowedInvoiceColumns[0].xmlKey in invoiceObj['$']
          ) {
            result[this.allowedInvoiceColumns[0].value] =
              invoiceObj['$'][this.allowedInvoiceColumns[0].xmlKey];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[0].xmlKey} is absent.`,
            });
          }

          // vendor invoice number
          if (this.allowedInvoiceColumns[1].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[1].value] =
              invoiceObj[this.allowedInvoiceColumns[1].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[1].xmlKey} is absent.`,
            });
          }

          // FieldTicketNumbers
          if (this.allowedInvoiceColumns[2].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[2].value] =
              invoiceObj[this.allowedInvoiceColumns[2].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[2].xmlKey} is absent.`,
            });
          }

          // VendorInvoiceDate
          if (this.allowedInvoiceColumns[3].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[3].value] =
              invoiceObj[this.allowedInvoiceColumns[3].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[3].xmlKey} is absent.`,
            });
          }

          // TreatmentType
          if (this.allowedInvoiceColumns[4].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[4].value] =
              invoiceObj[this.allowedInvoiceColumns[4].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[4].xmlKey} is absent.`,
            });
          }

          // GST
          if (
            this.allowedInvoiceColumns[5].xmlKey in invoiceObj &&
            !isNaN(Number(invoiceObj[this.allowedInvoiceColumns[5].xmlKey][0]))
          ) {
            result[this.allowedInvoiceColumns[5].value] = Number(
              invoiceObj[this.allowedInvoiceColumns[5].xmlKey][0]
            );
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[5].xmlKey} is absent or not a number(please remove $ or , or other special characters).`,
            });
          }

          // PST
          if (
            this.allowedInvoiceColumns[6].xmlKey in invoiceObj &&
            !isNaN(Number(invoiceObj[this.allowedInvoiceColumns[6].xmlKey][0]))
          ) {
            result[this.allowedInvoiceColumns[6].value] = Number(
              invoiceObj[this.allowedInvoiceColumns[6].xmlKey][0]
            );
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[6].xmlKey} is absent or not a number(please remove $ or , or other special characters).`,
            });
          }

          // HST
          if (
            this.allowedInvoiceColumns[7].xmlKey in invoiceObj &&
            !isNaN(Number(invoiceObj[this.allowedInvoiceColumns[7].xmlKey][0]))
          ) {
            result[this.allowedInvoiceColumns[7].value] = Number(
              invoiceObj[this.allowedInvoiceColumns[7].xmlKey][0]
            );
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[7].xmlKey} is absent or not a number(please remove $ or , or other special characters).`,
            });
          }

          // ServiceOrderNumber
          if (this.allowedInvoiceColumns[8].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[8].value] =
              invoiceObj[this.allowedInvoiceColumns[8].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[8].xmlKey} is absent.`,
            });
          }

          // ProgramNumber
          if (this.allowedInvoiceColumns[9].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[9].value] =
              invoiceObj[this.allowedInvoiceColumns[9].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[9].xmlKey} is absent.`,
            });
          }

          // Comment
          if (this.allowedInvoiceColumns[10].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[10].value] =
              invoiceObj[this.allowedInvoiceColumns[10].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[10].xmlKey} is absent.`,
            });
          }

          // Action
          if (this.allowedInvoiceColumns[11].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[11].value] =
              invoiceObj[this.allowedInvoiceColumns[11].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[11].xmlKey} is absent.`,
            });
          }

          // QuickPay
          if (this.allowedInvoiceColumns[12].xmlKey in invoiceObj) {
            result[this.allowedInvoiceColumns[12].value] =
              invoiceObj[this.allowedInvoiceColumns[12].xmlKey][0];
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[12].xmlKey} is absent.`,
            });
          }

          // QuickPay_Discount
          if (
            this.allowedInvoiceColumns[13].xmlKey in invoiceObj &&
            !isNaN(Number(invoiceObj[this.allowedInvoiceColumns[13].xmlKey][0]))
          ) {
            result[this.allowedInvoiceColumns[13].value] = Number(
              invoiceObj[this.allowedInvoiceColumns[13].xmlKey][0]
            );
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[13].xmlKey} is absent or wrong value require number without special characters.`,
            });
          }

          // QuickPay_Net_Days
          if (
            this.allowedInvoiceColumns[14].xmlKey in invoiceObj &&
            !isNaN(Number(invoiceObj[this.allowedInvoiceColumns[14].xmlKey][0]))
          ) {
            result[this.allowedInvoiceColumns[14].value] = Number(
              invoiceObj[this.allowedInvoiceColumns[14].xmlKey][0]
            );
          } else {
            this.errorDoc.push({
              message: `Invoice ${counter}: ${this.allowedInvoiceColumns[14].xmlKey} is absent or wrong value require number without special characters.`,
            });
          }

          result['usersName'] = this.usersName;
          result['ROW_ID'] = counter;
          counter += 1;
          const services = invoiceObj['serviceData'][0].Service;
          const serviceList = [];
          services.forEach((service, index) => {
            const serviceObj = {};
            // validate services

            // validate name
            if (
              '$' in service &&
              this.allowedServiceColumns[0].xmlKey in service['$']
            ) {
              serviceObj[this.allowedServiceColumns[0].value] =
                service['$'][this.allowedServiceColumns[0].xmlKey];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[0].value}`,
              });
            }

            // StartDate && endDate
            if ('StartDate' in service && 'EndDate' in service) {
              serviceObj[this.allowedServiceColumns[1].value] =
                service['StartDate'][0];
              serviceObj[this.allowedServiceColumns[1].value] = `${serviceObj[this.allowedServiceColumns[1].value]
                }-${service['EndDate'][0]}`;
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid StartDate or EndDate`,
              });
            }

            // UOM
            if (this.allowedServiceColumns[2].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[2].value] =
                service[this.allowedServiceColumns[2].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[2].xmlKey}`,
              });
            }

            // Quantity
            if (
              this.allowedServiceColumns[3].xmlKey in service &&
              !isNaN(Number(service[this.allowedServiceColumns[3].xmlKey][0]))
            ) {
              serviceObj[this.allowedServiceColumns[3].value] = Number(
                service[this.allowedServiceColumns[3].xmlKey][0]
              );
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[3].xmlKey}`,
              });
            }

            //Discount
            if (
              this.allowedServiceColumns[4].xmlKey in service &&
              !isNaN(Number(service[this.allowedServiceColumns[4].xmlKey][0]))
            ) {
              serviceObj[this.allowedServiceColumns[4].value] = Number(
                service[this.allowedServiceColumns[4].xmlKey][0]
              );
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[4].xmlKey}`,
              });
            }

            //GST
            if (this.allowedServiceColumns[5].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[5].value] =
                service[this.allowedServiceColumns[5].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[5].xmlKey}`,
              });
            }

            //PST
            if (this.allowedServiceColumns[6].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[6].value] =
                service[this.allowedServiceColumns[6].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[6].xmlKey}`,
              });
            }

            //HST
            if (this.allowedServiceColumns[7].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[7].value] =
                service[this.allowedServiceColumns[7].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[7].xmlKey}`,
              });
            }

            //Flexible service
            if (this.allowedServiceColumns[8].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[8].value] =
                service[this.allowedServiceColumns[8].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[8].xmlKey}`,
              });
            }

            //service_price
            if (
              this.allowedServiceColumns[9].xmlKey in service &&
              !isNaN(Number(service[this.allowedServiceColumns[9].xmlKey][0]))
            ) {
              serviceObj[this.allowedServiceColumns[9].value] = Number(
                service[this.allowedServiceColumns[9].xmlKey][0]
              );
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[9].xmlKey}`,
              });
            }

            //Comment
            if (this.allowedServiceColumns[10].xmlKey in service) {
              serviceObj[this.allowedServiceColumns[10].value] =
                service[this.allowedServiceColumns[10].xmlKey][0];
            } else {
              this.errorDoc.push({
                message: `Invoice ${counter}: has invalid ${this.allowedServiceColumns[10].xmlKey}`,
              });
            }

            const afes = service['afes'];
            const afeList = [];
            afes.forEach((afe, afeIndex) => {
              const afeObj = {};

              // validate codings

              //AFE_or_CostCenterWell
              if (this.allowedCodingColumns[0].xmlKey in afe) {
                afeObj[this.allowedCodingColumns[0].value] =
                  afe[this.allowedCodingColumns[0].xmlKey][0];
              } else {
                this.errorDoc.push({
                  message: `Invoice ${counter}: has invalid ${this.allowedCodingColumns[0].xmlKey}`,
                });
              }

              //CostCodeChain
              if (this.allowedCodingColumns[1].xmlKey in afe) {
                afeObj[this.allowedCodingColumns[1].value] =
                  afe[this.allowedCodingColumns[1].xmlKey][0];
              } else {
                this.errorDoc.push({
                  message: `Invoice ${counter}: has invalid ${this.allowedCodingColumns[1].xmlKey}`,
                });
              }

              //CodingPercentage
              if (
                this.allowedCodingColumns[2].xmlKey in afe &&
                !isNaN(Number(afe[this.allowedCodingColumns[2].xmlKey][0]))
              ) {
                afeObj[this.allowedCodingColumns[2].value] = Number(
                  afe[this.allowedCodingColumns[2].xmlKey][0]
                );
              } else {
                this.errorDoc.push({
                  message: `Invoice ${counter}: has invalid ${this.allowedCodingColumns[2].xmlKey}`,
                });
              }

              //ServiceName
              if (this.allowedCodingColumns[3].xmlKey in afe) {
                afeObj[this.allowedCodingColumns[3].value] =
                  afe[this.allowedCodingColumns[3].xmlKey][0];
              } else {
                this.errorDoc.push({
                  message: `Invoice ${counter}: has invalid ${this.allowedCodingColumns[3].xmlKey}`,
                });
              }
              afeList.push(afeObj);
            });
            serviceObj['afes'] = afeList;
            serviceList.push(serviceObj);
          });
          result['serviceData'] = serviceList;
          fileData.push(result);
        }
        return fileData;
      } catch (error) {
        this.$toasted.show('Error Reading XML File', {
          type: 'error',
          duration: '3000',
        });
      }
    },
    async validateImportedInvoices() {
      try {
        const response = await this.$axios.post(
          '/vendor/create/invoice/validate/template/' +
          this.companyId +
          '/' +
          this.vendorId,
          this.doc
        );
        if (response) {
          this.doc = response.data.updatedInvoiceData;
          this.errorDoc = response.data.validationResponse;
          this.isLoading = false;
          if (this.errorDoc.length === 0) {
            this.isInvoicesValidated = true;
          }
          const message =
            this.errorDoc.length > 0
              ? 'Validation Failed'
              : 'Successfully validated Invoice Template';
          const status = this.errorDoc.length > 0 ? 'error' : 'success';
          this.$toasted.show(message, {
            type: status,
            duration: '3000',
          });
        } else {
          Promise.reject(response);
        }
      } catch (error) {
        this.isLoading = false;
        this.$toasted.show('validation Failed', {
          type: 'error',
          duration: '3000',
        });
      }
    },
    async insertImportedInvoices() {
      try {
        const response = await this.$axios.post(
          '/vendor/create/invoice/import/template/' +
          this.companyId +
          '/' +
          this.vendorId,
          this.doc
        );
        this.isInvoicesValidated = false;
        if (response) {
          this.$toasted.show('Successfully imported Invoice Template', {
            type: 'success',
            duration: '3000',
          });
        } else {
          Promise.reject(response);
        }
      } catch (error) {
        this.$toasted.show('Error importing Invoice Template', {
          type: 'error',
          duration: '3000',
        });
        console.log(error, '::::error');
      }
    },
    formatString(value) {
      return `${value}`.replace(/\s/g, '').toLowerCase();
    },
    // The above code is parsing an excel file and converting it into a JSON object.
    prepareInvoiceList(result) {
      let invoiceList = [];
      const serviceSequenceColumn = this.formatString('Services');
      const invoiceCodingSequenceColumn = this.formatString('Invoice Coding');
      const invoiceLength = [];

      // validate invoice columns, format Invoice<i> (i = 1, 2,....);
      result.forEach((el, i) => {
        const invoiceSequenceCheck = el.some(
          (col) =>
            this.formatString(col) ===
            this.formatString(`Invoice${invoiceLength.length + 1}`)
        );
        // get invoice index in template
        if (invoiceSequenceCheck) invoiceLength.push(i);
      });

      if (invoiceLength.length === 0) {
        this.errorDoc.push({
          index: -1, // common errors
          message: `Invoice file is empty or Wrong invoice Column sequence Please follow (Invoice<i> (i = 1, 2,....)).`,
        });
      }
      invoiceLength.forEach((index, counter) => {
        // get invoice number. invoice1, invoice2, ...
        const invoiceCount = counter + 1;
        // check invoice fields values

        // remove empty columns
        const invoiceColumns = result[index + 1].filter((el) => el.length > 0);
        // check invoice field columns
        const hasInvoiceFieldColumns = invoiceColumns.find(
          (el) =>
            !this.allowedInvoiceColumns.some(
              (col) => this.formatString(col.xlkey) === this.formatString(el)
            )
        );

        // Checking if the invoice field columns are correct.
        if (hasInvoiceFieldColumns) {
          // wrong invoice field columns
          this.errorDoc.push({
            index: invoiceCount,
            message: `Wrong invoice Column (${hasInvoiceFieldColumns}) in invoice${invoiceCount}`,
          });
        } else {
          // get invoice field values
          const invoiceFieldValues = result[index + 2];
          const obj = {};
          this.allowedInvoiceColumns.forEach((col, i) => {
            obj[col.value] = invoiceFieldValues[i];
          });
          invoiceList[counter] = obj;
          invoiceList[counter]['ROW_ID'] = invoiceCount;
          invoiceList[counter]['usersName'] = this.usersName;

          // check service column. Services1
          const serviceSequenceCheck = result[index + 3].some(
            (col) =>
              this.formatString(col) ===
              this.formatString(`Services${invoiceCount}`)
          );

          if (serviceSequenceCheck) {
            // check service fields;

            // remove empty column values
            const serviceColumns = result[index + 4].filter(
              (el) => el.length > 0
            );

            const hasValidServiceFeildColumns = serviceColumns.find(
              (el) =>
                !this.allowedServiceColumns.some(
                  (col) =>
                    this.formatString(col.xlkey) === this.formatString(el)
                )
            );

            if (hasValidServiceFeildColumns) {
              // wrong service fields
              this.errorDoc.push({
                index: invoiceCount,
                message: `Wrong Service Column (${hasValidServiceFeildColumns}) in Services${invoiceCount}`,
              });
            } else {
              // check added service rows last index for current invoiceCount
              let serviceIndex = result.findIndex(
                (el) =>
                  this.formatString(el[0].toString()) ===
                  this.formatString(
                    `Invoice Coding${invoiceCount}(optional)`
                  ) ||
                  this.formatString(el[0].toString()) ===
                  this.formatString(`Invoice${invoiceCount + 1}`)
              );

              // end of import rows
              // Creating an array of objects.
              if (serviceIndex === -1) serviceIndex = result.length;
              const services = [];
              for (let j = index + 5; j < serviceIndex; j++) {
                const obj = {};
                this.allowedServiceColumns.forEach((col, i) => {
                  obj[col.value] = result[j][i];
                });
                services.push(obj);
              }
              invoiceList[counter] = {
                ...invoiceList[counter],
                serviceData: services,
              };
              // check for invoice coding columns
              const invoiceCodingColumn = this.formatString(
                `Invoice Coding${invoiceCount}(optional)`
              );
              if (
                result[index + 8].filter((el) => el.length > 0).length > 0 &&
                this.formatString(result[index + 8].toString()).includes(
                  this.formatString(`Invoice Coding${invoiceCount}(optional)`)
                )
              ) {
                // remove empty values
                const codingColumns = result[index + 9].filter(
                  (el) => el.length > 0
                );

                const hasValidCodingColumns = codingColumns.find(
                  (el) =>
                    !this.allowedCodingColumns.some(
                      (col) =>
                        this.formatString(col.xlkey) === this.formatString(el)
                    )
                );

                if (hasValidCodingColumns) {
                  // invalid conding columns
                  this.errorDoc.push({
                    index: invoiceCount,
                    message: `Wrong Invoice Coding Column (${hasValidCodingColumns}) in Invoice Coding${invoiceCount}(optional)`,
                  });
                } else {
                  let codingIndex = result.findIndex(
                    (el, i) => el[0] === `Invoice${invoiceCount + 1}`
                  );

                  if (codingIndex === -1) codingIndex = result.length;

                  const afes = [];
                  for (let j = index + 10; j < codingIndex; j++) {
                    const obj = {};
                    this.allowedCodingColumns.forEach((col, i) => {
                      obj[col.value] = result[j][i];
                    });
                    afes.push(obj);
                  }
                  invoiceList[counter].serviceData.forEach((service) => {
                    // this.allowedServiceColumns[0].value = 'serviceName'
                    const afeData = afes.filter(
                      (obj) =>
                        this.formatString(
                          service[this.allowedServiceColumns[0].value]
                        ) ===
                        this.formatString(
                          obj[this.allowedServiceColumns[0].value]
                        )
                    );
                    service['afes'] = afeData;
                  });
                }
              } else {
                // wrong invoice coding column
                this.errorDoc.push({
                  index: invoiceCount,
                  message: `Wrong Invoice Coding Sequence in invoice${invoiceCount}`,
                });
              }
            }
          } else {
            // invalid service sequence column
            this.errorDoc.push({
              index: invoiceCount,
              message: `Wrong Service Sequence in invoice${invoiceCount}`,
            });
          }
        }
      });

      // filter out wrong invoices
      if (this.errorDoc.length > 0) {
        invoiceList = invoiceList.filter((el) =>
          this.errorDoc.some((err) => err.index !== el.ROW_ID)
        );
      }
      return invoiceList;
    },
    get_company() {
      this.$http
        .get('/get/company/avatar/' + this.companyId)
        .then((response) => {
          if (response.data.avatar) {
            this.avatar = response.data.avatar;
          }
        })
        .catch((error) => {
          //console.log("Error getting avatar " + error);
        });
      if (this.fake_vendor) {
        this.$axios
          .get(
            '/vendor/get/company_address/v2/' +
            this.companyId +
            '/' +
            this.fake_vendor.vid
          )
          .then((response) => {
            this.companyInfo = response.data;
          })
          .catch((error) => { });
      } else {
        this.$axios
          .get('/vendor/get/company_address/' + this.companyId)
          .then((response) => {
            this.companyInfo = response.data;
          })
          .catch((error) => { });
      }
    },
  },
};
</script>

<style lang="scss">
.vendor-table {
  overflow-x: hidden !important;
}

@media screen and (max-width: 767px) {
  .vendor-table {

    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(3),
    td:nth-child(3) {
      display: none !important;
    }

    td,
    th {
      width: 50%;
    }
  }
}

@media screen and (max-width: 580px) {
  .vendor-table {

    td,
    th {
      width: 50%;
    }
  }
}
</style>
