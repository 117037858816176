var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-row",
        { attrs: { id: "border" } },
        [
          _c("b-col", { staticStyle: { "margin-right": "10px" } }, [
            _c("h4", { staticClass: "mb-3" }, [_vm._v("Billing From :")]),
            _vm.hasVendorData
              ? _c("div", [
                  _c("div", { staticClass: "mt-3 mb-3 d-flex" }, [
                    _vm.vendorAvatar
                      ? _c("img", {
                          staticClass: "companylogo mr-3",
                          attrs: { src: _vm.vendorAvatar }
                        })
                      : _vm._e(),
                    _c("div", [
                      _c("span", [
                        _c("i", { staticClass: "fa fa-building" }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.vendorDetails.company_name)
                        )
                      ]),
                      _c("br"),
                      _c("span", [
                        _c("i", { staticClass: "fa fa-user" }),
                        _vm._v(" " + _vm._s(_vm.usersName) + " ")
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("b", [_vm._v("Address: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.vendorDetails.addressline1) +
                            ",\n              " +
                            _vm._s(_vm.vendorDetails.addressline2)
                        )
                      ])
                    ]),
                    _c("div", [
                      _c("b", [_vm._v(" City " + _vm._s("&") + " Province: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.vendorDetails.city) +
                            ", " +
                            _vm._s(_vm.vendorDetails.province)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "text-uppercase" }, [
                      _c("b", [_vm._v(" Postal Code: ")]),
                      _vm._v(
                        _vm._s(_vm.vendorDetails.postalcode) + "\n          "
                      )
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Phone Num: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.phone_num))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Fax Num: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.fax_num))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("GST: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.gst))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("RCN: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.rcn))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Bank Account: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.bankaccount))
                    ])
                  ])
                ])
              : _c(
                  "div",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    })
                  ],
                  1
                )
          ]),
          _c("b-col", [
            _c("h4", { staticClass: "mb-3" }, [_vm._v("Bill To :")]),
            _vm.hasCompanyData
              ? _c("div", { staticClass: "mt-3 mb-4" }, [
                  _vm.avatar
                    ? _c("img", {
                        staticClass: "companylogo",
                        attrs: { src: _vm.avatar }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "mb-4 mt-2" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.companyId,
                            expression: "companyId"
                          }
                        ],
                        staticClass: "rt-select w-75",
                        staticStyle: {
                          "border-radius": "5px",
                          "border-color": "lightgrey",
                          height: "38px"
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.companyId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.get_company
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { value: "0", disabled: "" } }, [
                          _vm._v("Select Company")
                        ]),
                        _vm._l(_vm.companyData, function(company) {
                          return _c(
                            "option",
                            { domProps: { value: company.companyid } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(company.cn) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  !isNaN(Number(_vm.companyId)) && _vm.companyInfo
                    ? _c("div", [
                        _c("div", [
                          _c("b", [_vm._v("Company Address: ")]),
                          _vm._v(
                            _vm._s(_vm.companyInfo.addressline1) +
                              ",\n            " +
                              _vm._s(_vm.companyInfo.addressline2) +
                              "\n          "
                          )
                        ]),
                        _c("div", [
                          _c("b", [
                            _vm._v(" City " + _vm._s("&") + " Province: ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.companyInfo.city) +
                              ",\n            " +
                              _vm._s(_vm.companyInfo.province) +
                              "\n          "
                          )
                        ]),
                        _c("div", [
                          _c("b", [_vm._v(" Postal code: ")]),
                          _vm._v(" " + _vm._s(_vm.companyInfo.postalcode))
                        ])
                      ])
                    : _vm._e()
                ])
              : _c(
                  "div",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    })
                  ],
                  1
                )
          ])
        ],
        1
      ),
      _vm.companyInfo
        ? _c("b-row", { staticClass: "mt-4 mr-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h5", [_vm._v("Attach file:")]),
              _c("input", {
                ref: "csvFile",
                staticClass: "mt-2",
                attrs: {
                  onclick: "this.value=null;",
                  accept: ".xlsx,.xls,.xml",
                  id: "fileInput",
                  type: "file"
                },
                on: { change: _vm.importInvoiceTemplate }
              })
            ])
          ])
        : _vm._e(),
      _vm.errorDoc.length > 0
        ? _c("div", { staticClass: "mt-4 ml-1 mb-3" }, [
            _c("h5", [_vm._v("Validtion Error:")]),
            _c(
              "ul",
              _vm._l(_vm.errorDoc, function(val, i) {
                return _c("li", [
                  _vm._v("\n        " + _vm._s(val.message) + "\n      ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("span", { staticClass: "mb-3 ml-1 mt-4" }, [
            _vm._v("\n    Validating Invoices...\n  ")
          ])
        : _vm._e(),
      _vm.companyInfo
        ? _c(
            "b-button",
            {
              staticClass: "mt-3",
              staticStyle: { height: "min-content" },
              attrs: {
                onclick: "this.value=null;",
                variant: "success",
                size: "sm",
                disabled: !_vm.isInvoicesValidated
              },
              on: { click: _vm.insertImportedInvoices }
            },
            [_vm._v("Import Invoices\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }